import { Controller } from '@hotwired/stimulus';
import displayFlash from '../utils/displayFlash';

interface CalendlyProps {
  url: string;
  parentElement: Element;
  prefill: any;
  utm: any;
}

type CalendlyMessageEvent = MessageEvent<{ event: string }>;

declare global {
  interface Window {
    Calendly: {
      initInlineWidget: (props: CalendlyProps) => void;
    };
    mixpanel: any;
  }
}

export default class extends Controller {
  /* eslint-disable max-len */
  static values = {
    coachCalendlyUrl: String,
    userFullName: String,
    userEmailAddress: String,
    userOrganisation: String,
    coachName: String,
    campaign: String,
  };

  declare userFullNameValue: string;
  declare userEmailAddressValue: string;
  declare coachCalendlyUrlValue: string;
  declare userOrganisationValue: string;
  declare coachNameValue: string;
  declare campaignValue: string;
  declare boundCalendlyHandler: (e: CalendlyMessageEvent) => void;

  connect() {
    this.loadCalendlyScript();
    this.boundCalendlyHandler = this.calendlyHandler.bind(this);
    window.addEventListener('message', this.boundCalendlyHandler);
    this.initTimeout();
  }

  disconnect() {
    window.removeEventListener('message', this.boundCalendlyHandler);
  }

  initTimeout() {
    window.setTimeout(() => {
      window.mixpanel.track('BookingTimeout', { time: Date.now() / 1000 });
      window.location.replace('https://bookings.sanctus.io/home');
    }, 1000 * 60 * 5);
  }

  loadCalendlyScript() {
    if (window.Calendly) {
      this.initCalendlyWidget();
    } else {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.onload = this.initCalendlyWidget.bind(this);

      document.head.appendChild(script);
    }
  }

  initCalendlyWidget() {
    window.Calendly.initInlineWidget({
      url: this.coachCalendlyUrl(),
      parentElement: this.element,
      prefill: {
        name: this.userFullNameValue,
        email: this.userEmailAddressValue,
        customAnswers: {
          a1: this.userOrganisationValue,
        },
      },
      utm: {
        utmCampaign: this.campaignValue,
        utmSource: `SanctusBookingSystem-${process.env.RAILS_ENV}`,
        utmContent: this.userEmailAddressValue,
        utmMedium: 'CalendarPage',
      },
    });
  }

  calendlyHandler(e: CalendlyMessageEvent) {
    if (this.isCalendlyEvent(e)) {
      const params = new URLSearchParams(window.location.search);
      if (e.data.event === 'calendly.event_scheduled') {
        document.getElementById('back-to-coaches')?.classList.add('invisible');
        window.mixpanel.track('Session Booked', { time: Date.now() / 1000, utm_source: params.get('utm_source'), coach: this.coachNameValue });
        displayFlash('Your session has been confirmed.', 8000);
        window.location.href = '/home';
      }
    }
  }

  isCalendlyEvent(e: CalendlyMessageEvent) {
    return e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
  }

  coachCalendlyUrl() {
    return `${this.coachCalendlyUrlValue}?hide_gdpr_banner=1`;
  }
}
